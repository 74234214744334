import React from "react";
import {MenuItem, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        width: '100%',
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #8BD2C1',
        },
        '& .MuiFormLabel-root.Mui-focused' : {
            color: '#8BD2C1'
        },
        '& .MuiFormLabel-root' : {
            padding: 0,
            fontSize: '1.05rem',
            fontFamily: 'IBM Plex Sans',
            fontWeight: 400,
            lineHeight: 1
        },
        '& .MenuItem-root' :{
            maxHeight: 400,
            // width: 300,
        }
    },
}));


const Picklist = ({label, value, error, options, setValue, name, onBlur}) =>{
    const classes = useStyles();

    return (
        <>
            <TextField
                id="standard-select"
                className={classes.root}
                error={error}
                name={name}
                required
                select
                label={label}
                value={value}
                onBlur={onBlur}
                onChange={(e) => setValue(e.target.value)}
                helperText={error ? 'Please fill in this field!': ''}
                variant="standard"
            >
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        </>
    )
}

export default Picklist;