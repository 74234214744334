import React, { useState } from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    TextField,
    Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Alert} from "@material-ui/lab";
import PhoneInput from "../../PhoneInput";
import Picklist from "../../Picklist";
import BasicTable from "../../BasicTable";


const useStyles = makeStyles((theme) => ({
    pageWrapper: {
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.main,
    },
    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        background: theme.palette.primary.main,
        width: '100%',
        color: "black",
        borderRadius: "4px",
        border: "none"
    },
    cardColor: {
        background: theme.palette.background.default,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    columnTutor: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '725px'
    },
    formBlock: {
      paddingTop: '1rem',
      marginBottom: '5ex',
      paddingLeft: '4ex',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.primary.main,
    },

    formBlockTitle: {
        backgroundColor: theme.palette.primary.main,
        padding: '1em',
        marginTop: 0,
}
}));

const age = ['9', '10', '11', '12', '13' , '14', '15', '16', '17', '18', '19', '20' , '21', '22' , '23', '24'];
const educationLevel = ['Undergraduate', 'Masters', 'Doctorate', 'Post-doctorate'];
const agreeList = ['No', 'Yes'];
const feedbackList = ['Email newsletter', 'Facebook', 'Indeed', 'Internet search', 'LinkedIn',
                    'Other online jobs board', 'Oxford Summer Courses\' event', 'Poster',
                    'Twitter', 'University', 'Word of mouth'];
const roles = {
    online1to1: 'Online 1:1 Tutor (Melio)',
    onlineGroup: 'Online Group Tutor (Melio)',
    onlineTeaching: 'Online Teaching Assistant (Melio)',
}


const Form = () =>{
    const classes = useStyles();
    const [error, setError] = useState({forename: false, surname: false, preferredName : false,
        dob : false, email: false, phone : false, addressLine1 : false, addressLine2 : false,city: false, postCode: false,
        country: false, role: false, minAge: false, maxAge: false, level: false, educationalList: false,
        certification: false, employmentHistory: false, workingBarred: false, disciplinaryAction: false,
        feedbackPlace: false, interested: false, agreeInfo: false, userAgreement: false, workInCountry: false, availability: false, age: false});

    const [data, setData] = useState({forename: '', surname : '' , preferredName : '',
        dob: '', email: '', phone: '', addressLine1: '', addressLine2 : '', city: '', postCode: '',
        country: '', role : '', minAge: '', maxAge: '', level: '', educationalList: '', workInCountry: '',
        certification: '', employmentHistory: '', workingBarred: '', disciplinaryAction: '',
        feedbackPlace: '', newsletter: false, interested: '', agreeInfo: false, userAgreement: false, availability: ''});

    const [role, setRole] = useState({online1to1: false, onlineGroup: false, onlineTeaching: false});
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        let value = {...data, role: getRole()};
        checkStudentAge(value);
        checkFields(value) ? setOpenAlert(true) : doPost(value);
    }

    const doPost = (value) => {
        setOpenBackdrop(true);
        console.log(value);
        fetch('https://melioeducation.secure.force.com/developerData/services/apexrest/Tutor/ApplicationForm/', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods':'POST',
                'Content-Type':'application/json'
            },
            body: JSON.stringify(value)
        })
            .then(result => {
            if (result.status !== 200) {
                console.log(result);
                alert('Something went wrong');
            } else {
                setOpenBackdrop(false);
                setOpenSuccess(true);
            }
        }).then(() => {
        })
    }

    const checkFields = (value) => {
        let obj = {};
        let isError = false;
        console.log('data', value)
        for (const valueKey in value) {
            if ( ! value[valueKey]
                && valueKey !== 'addressLine2'
                && valueKey !== 'newsletter'
                && valueKey !== 'agreeInfo'
                && valueKey !== 'userAgreement') {

                obj[valueKey] = true;
                isError = true;

            }
        }
        setError({...error, ...obj});
        return isError;
    }

    const handleError = (event) => {
        let name = event.target.name;
        if (name === 'addressLine2') return;
        const value = (name === 'online1to1' || name === 'onlineGroup' || name === 'onlineGroup') ? event.target.checked : event.target.value;
        name = (name === 'online1to1' || name === 'onlineGroup' || name === 'onlineGroup') ? 'role' : name;
        if (name === 'email') validEmail(value);
        const ageError = (name === 'minAge' || name === 'maxAge') ? checkStudentAge() : false;
        value === '' ? setError({...error, [name] : true}) : setError({...error, [name]: false, age: ageError})
    }

    const validEmail = (value) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValid = re.test(String(value).toLowerCase());
        setError({...error, email: !isValid});
    }

    const checkStudentAge = () => {
        const minAge = data.minAge;
        const maxAge = data.maxAge;
        let ageError = false;
        if (minAge && maxAge){
            ageError = parseInt(maxAge) < parseInt(minAge);
        }
        return ageError;
    }

    const erHandler = () => {
        if (data.availability)
            setError({...error, availability: false});
    }

    const getRole = () => {
        let selectRole = '';
        for (const val in role){
            if (role[val])
                selectRole += roles[val] + ';';
        }
        return selectRole;
    }


    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
        setOpenSuccess(false);
    }

    return (
        <div className={classes.pageWrapper}>
            <div className={classes.columnTutor}>
                <Typography component="h1" variant="h2" gutterBottom>Tutor application form</Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    Please complete the form in full, including any gaps in your employment history. 
                    Please note that our teaching positions are non-residential (excluding EFL)
                    and we do not offer accommodation. 
                    We no longer accept CVs as part of our recruitment process, 
                    but ask all applicants to give the last five years of employment history. 
                    This is in order to comply with Safer Recruiting as part of our Safeguarding policy. 
                    If you have any questions about the form or our policies, please Contact us.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    Oxford Summer Courses is committed to safeguarding and protecting children.
                    We check references and undertake enhanced Disclosure and Barring Services (DBS)
                    checks to ensure all our staff are suitable to work with children.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    <b>
                        If you have worked with us previously then please do not complete this form. 
                        We will be in touch soon to gather your availability.
                    </b>
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    This form is for those applying to teach in-person courses during the summer 
                    with <a href="https://oxfordsummercourses.com/careers/"> Oxford Summer Courses</a> 
                    and online courses taking place throughout the year 
                    with <a href="https://www.melioeducation.com/">Melio Education</a>.
                </Typography>
                <form onSubmit={handleSubmit} noValidate>
                    <Typography variant="h5" component="h2" className={classes.formBlockTitle}>Personal details</Typography>
                    <div className={classes.formBlock}>
                        <Picklist label={'Right to work in the country where you live'}
                                  options={agreeList}
                                  name={'workInCountry'}
                                  value={data.workInCountry}
                                  onBlur={handleError}
                                  setValue={(val) => setData({...data, workInCountry: val})}
                                  error={error.workInCountry}
                        />
                        <TextField className={classes.root} inputProps={{maxLength: 50}}
                                   error={error.forename}
                                   name="forename" required label="Forename"
                                   value={data.forename} placeholder='Enter your Forename'
                                   helperText={error.forename ? 'Please fill in this field!': ''}
                                   onChange={(e) => setData({...data, forename: e.target.value})}
                                   onBlur={handleError}
                        />  
                        <TextField className={classes.root} inputProps={{maxLength: 50}}
                                   error={error.surname}
                                   name="surname" required label="Surname"
                                   value={data.surname} placeholder='Enter your Surname'
                                   helperText={error.surname ? 'Please fill in this field!': ''}
                                   onChange={(e) => setData({...data, surname: e.target.value})}
                                   onBlur={handleError}
                        />
                        <TextField className={classes.root} inputProps={{maxLength: 50}}
                                   error={error.preferredName}
                                   name="preferredName" required label="Preferred Name"
                                   value={data.preferredName} placeholder='Enter your Preferred Name'
                                   helperText={error.preferredName ? 'Please fill in this field!': ''}
                                   onChange={(e) => setData({...data, preferredName: e.target.value})}
                                   onBlur={handleError}
                        />
                        <TextField className={classes.root} required
                                   label="What is your date of birth?" type='date'
                                   error={error.dob} name='dob'
                                   value={data.dob} placeholder={'Enter your date of birth'}
                                   helperText={error.dob ? 'Please fill in this field!' : ''}
                                   InputLabelProps={{shrink:true}} onChange={e => setData({...data, dob: e.target.value})}
                                   onBlur={handleError}
                        />
                        <TextField className={classes.root} inputProps={{maxLength: 80}}
                                   required label="What's your email address?"
                                   error={error.email} name='email'
                                   type="email" value={data.email} placeholder='Enter your email address'
                                   onChange={(e) => setData({...data, email: e.target.value})}
                                   helperText={error.email ? "Invalid email address!" : ""}
                                   onBlur={handleError}
                        />
                        <PhoneInput getPhone={(val) => setData({...data, phone: val})}
                                    helpText={''}
                                    name="phone"
                                    error={error.phone}
                                    setError={(val) => setError({...error, phone: val})}/>
                        <TextField className={classes.root} inputProps={{maxLength: 80}}
                                   error={error.addressLine1}
                                   name="addressLine1" required label="Address Line 1"
                                   value={data.addressLine1} placeholder='Enter your Address Line 1'
                                   helperText={error.addressLine1 ? 'Please fill in this field!': ''}
                                   onChange={(e) => setData({...data, addressLine1: e.target.value})}
                                   onBlur={handleError}
                        />
                        <TextField className={classes.root} inputProps={{maxLength: 80}}
                                   error={error.addressLine2}
                                   name="addressLine2" required={false} label="Address Line 2"
                                   value={data.addressLine2} placeholder='Enter your Address Line 2'
                                   helperText={error.addressLine2 ? 'Please fill in this field!': ''}
                                   onChange={(e) => setData({...data, addressLine2: e.target.value})}
                                   onBlur={handleError}
                        />
                        <TextField className={classes.root} inputProps={{maxLength: 80}}
                                   error={error.city}
                                   name="city" required label="City"
                                   value={data.city} placeholder='Enter your City'
                                   helperText={error.city ? 'Please fill in this field!': ''}
                                   onChange={(e) => setData({...data, city: e.target.value})}
                                   onBlur={handleError}
                        />
                        <TextField className={classes.root} inputProps={{maxLength: 80}}
                                   error={error.postCode}
                                   name="postCode" required label="Postcode"
                                   value={data.postCode} placeholder='Enter your Postcode'
                                   helperText={error.postCode ? 'Please fill in this field!': ''}
                                   onChange={(e) => setData({...data, postCode: e.target.value})}
                                   onBlur={handleError}
                        />
                        <TextField className={classes.root} inputProps={{maxLength: 80}}
                                   error={error.country}
                                   name="country" required label="Country"
                                   value={data.country} placeholder='Enter your country'
                                   helperText={error.country ? 'Please fill in this field!': ''}
                                   onChange={(e) => setData({...data, country: e.target.value})}
                                   onBlur={handleError}
                        />
                    </div>
                    <Typography variant="h5" component="h2" className={classes.formBlockTitle}>Roles</Typography>
                    <div className={classes.formBlock}>

                        <Typography variant="body1" component="p" gutterBottom>
                            Please note that some roles and subjects are for specific to particular
                            locations and age groups. This form will show the subjects
                            available to students in your selected age range. 
                            We will also take into account your availability 
                            and relevant experience when reviewing your application. 
                            For more details please see our Careers pages.
                        </Typography>
                        <FormLabel error={error.role} className={classes.root} required component="legend">
                            Select the roles you would like to be considered for
                        </FormLabel>
                        <FormControlLabel className={classes.root} name='role'
                                          control={<Checkbox required
                                                             checked={role.online1to1}
                                                             onBlur={handleError}
                                                             onChange={() => {setRole({...role, online1to1: !role.online1to1})
                                                             }}
                                                             name="online1to1"/>}
                                          label={roles.online1to1}/>
                        <FormControlLabel className={classes.root}
                                          control={<Checkbox required
                                                             checked={role.onlineGroup}
                                                             onBlur={handleError}
                                                             onChange={() => {setRole({...role, onlineGroup: !role.onlineGroup})
                                                             }}
                                                             name="onlineGroup"/>}
                                          label={roles.onlineGroup}/>
                        <FormControlLabel className={classes.root}
                                          control={<Checkbox required
                                                             checked={role.onlineTeaching}
                                                             onBlur={handleError}
                                                             onChange={() => {setRole({...role, onlineTeaching: !role.onlineTeaching})
                                                             }}
                                                             name="onlineTeaching"/>}
                                          label={roles.onlineTeaching}/>
                        <FormLabel error={error.minAge || error.maxAge || error.age} className={classes.root} required component="legend">
                            Select the youngest and oldest students you would like to work with
                        </FormLabel>

                        <Picklist label={'Minimum age'}
                                  name={'minAge'}
                                  options={age}
                                  value={data.minAge}
                                  setValue={(val) => setData({...data, minAge: val})}
                                  onBlur={handleError}
                                  error={error.minAge}/>
                        <Picklist label={'Maximum age'}
                                  name={'maxAge'}
                                  options={age}
                                  value={data.maxAge}
                                  onBlur={handleError}
                                  setValue={(val) => setData({...data, maxAge: val})}
                                  error={error.maxAge}/>
                        {error.age ? <FormHelperText error={error.age}> Maximum age can't be lesser than minimum age!</FormHelperText> : ''}
                    </div>
                    <Typography variant="h5" component="h2" className={classes.formBlockTitle}>Availability</Typography>
                    <div className={classes.formBlock}>
                        <Typography variant="body1" component="p" gutterBottom>
                            Please note that OxSC in-person courses do not run for the full range of dates for all locations and Melio online courses may run throughout the year. If you are not yet sure of your availability, please complete this section as best as you can for now. Selecting many options does not commit you to working this number of courses.
                        </Typography>
                        <FormLabel error={error.availability} className={classes.root} required component="legend">
                            Now indicate when you would be available to work with us
                        </FormLabel>
                        <BasicTable onBlur={erHandler}
                            selAvailability={(value) => setData({...data, availability: value})}/>
                    </div>
                    <Typography variant="h5" component="h2" className={classes.formBlockTitle}>Educational background</Typography>

                    <div className={classes.formBlock}>
                        <Typography variant="body1" component="p" gutterBottom>
                          Please note that required qualifications and experience varies between roles. 
                          It is important to provide full detail so that we can review 
                          your application for the roles you have selected.
                        </Typography>
                        <Picklist label={'Select the highest level of education that you have completed'}
                                  options={educationLevel}
                                  name={'level'}
                                  value={data.level}
                                  onBlur={handleError}
                                  setValue={(val) => setData({...data, level: val})}
                                  error={error.level}/>
                        <TextField className={classes.root}
                                   error={error.educationalList}
                                   multiline
                                   required
                                   name="educationalList"
                                   value={data.educationalList}
                                   label='List your educational qualifications from undergraduate level'
                                   helperText={error.educationalList ?
                                       'Please fill in this field!':
                                       'For each qualification you should include: qualification type, subject, university, and completion date, including any qualifications you have not yet completed'}
                                   onChange={(e) => setData({...data, educationalList: e.target.value})}
                                   onBlur={handleError}
                        />
                        <Picklist label={'Do you have a teaching certification?'}
                                  options={agreeList}
                                  name={'certification'}
                                  onBlur={handleError}
                                  value={data.certification}
                                  setValue={(val) => setData({...data, certification: val})}
                                  error={error.certification}/>
                    </div>
                    <Typography variant="h5" component="h2" className={classes.formBlockTitle}>Employment history</Typography>

                    <div className={classes.formBlock}>
                        <Typography variant="body1" component="p" gutterBottom>
                            Please note this information is required 
                            for <a href="https://www.gov.uk/government/publications/safeguarding-children-and-safer-recruitment-in-education"> 
                            Safer Recruitment </a> purposes as part of our Safeguarding policy. 
                            An incomplete employment history with unexplained gaps may mean 
                            that we are unable to continue with your application.
                        </Typography>
                        <TextField className={classes.root}
                                   error={error.employmentHistory}
                                   multiline
                                   required
                                   name="employmentHistory"
                                   value={data.employmentHistory}
                                   label='List your employment history for the last five years, including an explanation of any gaps'
                                   helperText={error.employmentHistory ? 'Please fill in this field!': 'For each position you should include: role name, employer, start date, end date and brief description if relevant to the role(s) you are applying for.'}
                                   onChange={(e) => setData({...data, employmentHistory: e.target.value})}
                                   onBlur={handleError}
                        />
                    </div>
                    <Typography variant="h5" component="h2" className={classes.formBlockTitle}>Employment checks</Typography>

                    <div className={classes.formBlock}>
                        <Typography variant="body1" component="p" gutterBottom>
                            This post is covered by the <a href="https://www.legislation.gov.uk/uksi/2013/1198/pdfs/uksi_20131198_en.pdf">Rehabilitation of Offenders Act 1974 (Exceptions) Order 1975</a> because it is a post which involves working directly with children or young people. You are therefore required to declare whether you have any criminal convictions (or cautions or bind-overs) including those which are 'spent'.
                        </Typography>
                        <Typography variant="body1" component="p" gutterBottom> <b>Please note</b></Typography>
                        <Typography variant="body1" component="p" gutterBottom>
                            If your application is successful, prior to taking up your post, you will be required to undergo an Enhanced Disclosure process through the <a href="https://www.gov.uk/government/collections/dbs-checking-service-guidance--2">Disclosure Barring Service</a>. This will require you to provide a range of more than one piece of documentary evidence of your identity.
                        </Typography>
                        <Typography variant="body1" component="p" gutterBottom>
                            Although a criminal record involving offences against children is likely to debar you from appointment of this type of post, the existence of other criminal convictions will not necessary be a bar to employment.
                        </Typography>
                        <Typography variant="body1" component="p" gutterBottom>
                            Any criminal record information arising out of the disclosure process will be discussed with you before any final decision is made about your employment.
                        </Typography>
                        <Typography variant="body1" component="p" gutterBottom><b>
                            It is a criminal offence to apply for or accept a position (paid or unpaid) working with children if you are excluded from such work by virtue of a court order.
                        </b></Typography>
                        <Picklist label={'Are you included in any list of people barred from working with children?'}
                                  options={agreeList}
                                  onBlur={handleError}
                                  name={'workingBarred'}
                                  value={data.workingBarred}
                                  setValue={(val) => setData({...data, workingBarred: val})}
                                  error={error.workingBarred}/>
                        <Picklist label={'Have you ever been subject to any disciplinary action in a previous position?'}
                                  options={agreeList}
                                  name={'disciplinaryAction'}
                                  onBlur={handleError}
                                  value={data.disciplinaryAction}
                                  setValue={(val) => setData({...data, disciplinaryAction: val})}
                                  error={error.disciplinaryAction}/>
                        <Typography variant="body1" component="p" gutterBottom>
                          <b>
                              If you have selected 'yes' for any of the above questions please note that during the recruitment process you will be asked to disclose the details as part of our commitment to safeguarding and protecting children on our courses.
                          </b>
                        </Typography>
                    </div>
                    <Typography variant="h5" component="h2" className={classes.formBlockTitle}>Keeping in touch</Typography>

                    <div className={classes.formBlock}>
                        <Picklist label={'How did you hear about us?'}
                                  options={feedbackList}
                                  name={'feedbackPlace'}
                                  onBlur={handleError}
                                  value={data.feedbackPlace}
                                  setValue={(val) => setData({...data, feedbackPlace: val})}
                                  error={error.feedbackPlace}/>
                        <Typography variant="body1" component="p" gutterBottom>
                            We send out newsletters which include important updates about our courses, events taking place that you can join in with, information about new roles, referral schemes to earn bonuses, and more. We never sell your personal details to other companies for marketing purposes.
                        </Typography>
                        <FormControlLabel className={classes.root}
                                          control={<Checkbox checked={data.newsletter}
                                                             onChange={() => setData({...data, newsletter: !data.newsletter})}
                                                             name="newsletter"/>}
                                          label="Yes please, I would like to receive the recruitment newsletter"/>
                        <Typography variant="body1" component="p" gutterBottom>
                            <a href="https://www.universifyeducation.com/">Universify Education</a>  is our sister charity, helping students from non-traditional backgrounds feel empowered and excited about fulfilling their potential at highly selective universities
                        </Typography>
                        <Picklist label={'Would you be interested in opportunities with Universify Education?'}
                                  options={agreeList}
                                  name={'interested'}
                                  onBlur={handleError}
                                  value={data.interested}
                                  setValue={(val) => setData({...data, interested: val})}
                                  error={error.interested}/>
                    </div>
                    <Typography variant="h5" component="h2" className={classes.formBlockTitle}>Legal declarations</Typography>

                    <div className={classes.formBlock}>
                        <Typography variant="body1" component="p" gutterBottom>
                            As stated in the Oxford Summer Courses' GDPR notice , personal data will be shared with third-party information and communication tools solely for recruitment purposes.
                        </Typography>
                        <FormControlLabel className={classes.root}

                                          control={<Checkbox required checked={data.agreeInfo}
                                                             onChange={() => {setData({...data, agreeInfo: !data.agreeInfo});
                                                                 setError({...error, agreeInfo: !error.agreeInfo })
                                                             }}
                                                             name="agreeInfo"/>}
                                          label="I agree that the information I have provided will be used to send me information and manage my application"/>
                        <Typography variant="body1" component="p" gutterBottom>
                            I understand that, should any of the particulars I provide in this application be found to be false within my knowledge, or should there be any willful omission of material fact, this may be reported to the police as well as leading to my application being rejected or the contract being null and void if I have already been appointed.
                        </Typography>

                        <FormControlLabel className={classes.root}
                                          control={<Checkbox required checked={data.userAgreement}
                                                             onChange={() => {setData({...data, userAgreement: !data.userAgreement});
                                                                 setError({...error, userAgreement: !error.userAgreement })
                                                             }}
                                                             name="userAgreement"/>}
                                          label="By checking this box, I hereby confirm that the information I have given above is true"/>
                    </div>
                    <>

                        <Card className={classes.cardColor}>
                            <CardContent>
                                <Typography>
                                    <b>Thank you {data.forename} {data.surname} for application to work with Melio Education.</b>
                                    <p className={classes.marging}>
                                        <div>You {data.workInCountry === 'Yes'? 'have': "haven't"} right to work in the country where you live. </div>
                                        <div>You {data.workingBarred === 'Yes'? 'included': "didn't include "} in any barred list from working with children. </div>
                                        <div>You {data.disciplinaryAction === 'Yes'? 'was': "wasn't "} subject to any disciplinary action in a previous position. </div>
                                        <div>I {data.agreeInfo ? 'agree': "disagree "} that the information I have provided will be used to send me information and manage my application.</div>
                                        <div>I hereby confirm that the information I have given above is {data.userAgreement ? 'true': "false "} .</div>
                                    </p>
                                </Typography>
                            </CardContent>
                        </Card>
                    </>

                    <Button className={classes.button} type="submit" variant="contained" color="inherit" disableElevation>
                        Submit
                    </Button>
                </form>
            </div>
            <Snackbar open={openAlert} autoHideDuration={5000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity='warning'>
                    Please fill in all required fields!
                </Alert>
            </Snackbar>
            <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="success">
                    Successfully created
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default Form;