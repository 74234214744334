import React, {useState} from "react";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";

const BasicTable = ({selAvailability, onBlur}) => {
    const [rows, setRows] = useState([{name: '8:00 - 12:00 GMT', monday: false, tuesday: false, wednesday: false, thursday:false, friday: false},
        {name: '12:00 - 17:00 GMT', monday: false, tuesday: false, wednesday: false, thursday:false, friday: false},
        {name: '17:00 - 21:00 GMT', monday: false, tuesday: false, wednesday: false, thursday:false, friday: false}]);

    const checkBoxHandler = (event, day, index) => {
        let data = rows;
        data[index][day] = !rows[index][day];
        setRows([...data]);
        setAvailability();
    }

    const setAvailability = () => {
        let availability = {monday: '', tuesday: '', wednesday: '', thursday: '', friday: '' };
        for (const avail in availability){
            for (const row of rows) {
                const time = row.name.substring(0, row.name.length - 3).trim();
                if (row[avail]){
                    availability[avail] = availability[avail] ?
                        availability[avail].substring(availability[avail].length - 5) === time.substring(0, time.length - 8) ?
                            availability[avail].substring(0 , availability[avail].length - 5) + time.substring(time.length - 5)
                            : availability[avail]  + ', ' + time
                        : time;
                }
            }
        }

        selAvailability(`monday: ${availability.monday};tuesday: ${availability.tuesday};wednesday: ${availability.wednesday};thursday: ${availability.thursday};friday: ${availability.friday}`);}

    return (
        <>
        <Table onBlur={onBlur}>
            <TableHead>
                <TableRow>
                    <TableCell align="center"/>
                    <TableCell align="center">Monday</TableCell>
                    <TableCell align="center">Tuesday</TableCell>
                    <TableCell align="center">Wednesday</TableCell>
                    <TableCell align="center">Thursday</TableCell>
                    <TableCell align="center">Friday</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow key={row.name}>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center" size="small"
                                   onClick={(e) => checkBoxHandler(e,'monday',index)}>
                            <Checkbox checked={row.monday} />
                        </TableCell>
                        <TableCell align="center" size="small"
                                   onClick={(e) => checkBoxHandler(e, 'tuesday',  index)}>
                            <Checkbox checked={row.tuesday} />
                        </TableCell>
                        <TableCell align="center" size="small"
                                   onClick={(e) => checkBoxHandler(e, 'wednesday', index)}>
                            <Checkbox checked={row.wednesday} />
                        </TableCell>
                        <TableCell align="center" size="small"
                                   onClick={(e) => checkBoxHandler(e, 'thursday', index)}>
                            <Checkbox checked={row.thursday} />
                        </TableCell>
                        <TableCell align="center" size="small"
                                   onClick={(e) => checkBoxHandler(e, 'friday', index)}>
                            <Checkbox checked={row.friday} />
                        </TableCell>
                    </TableRow>
                    ))}
            </TableBody>
        </Table>
        </>
    )
}

export default BasicTable;