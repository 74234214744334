import React, { useState } from 'react';
import Layout from '../../../../components/Layout'
import Form from '../../../../services/salesforce/components/forms/tutor/Apply';

const ApplyForm = () => (
    <Layout>
      <Form />
    </Layout>
)

export default ApplyForm;
